import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Emailus from "../components/emailus";

const BookPage = ({ intl }) => {
	return (
		<Layout pageName="prices" siteTitle="REZERWACJE TYLKO PRZEZ EMAIL" intro="">
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
		
			<section className="wrapper container wrap narrow">
				<div className="solidBg overlap" style={{width:"100%"}}>
					<Emailus />
					<p className="center"><a className="btn" href="mailto:dragon.tree.travel@gmail.com">dragon.tree.travel@gmail.com</a></p>
				</div>
			</section>
		</Layout>
	)
}

export default injectIntl(BookPage)
